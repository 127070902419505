import { ExcludedPath, NameOverride } from './model';

export const DEFAULT_HOMEPAGE_PATH: string = '/';

export const DEFAULT_NAME_OVERRIDE: NameOverride[] = [
  {
    name: 'Homepage',
    path: DEFAULT_HOMEPAGE_PATH,
  },
];

export const DEFAULT_EXCLUDED_PAGES: ExcludedPath[] = [
  '/404/',
  '/404.html',
  '/dev-404-page/',
  '/sitemap/',
];
