import React from 'react';
import { Link } from 'gatsby';

const ListTree: React.FC<ListTreeProps> = ({ items }) => (
  <>
    {items.map(({ id, path, name, children }) => (
      <ul key={id} className="list-tree">
        <li className="list-tree__item">
          <Link className="list-tree__link" to={path}>
            {name}
          </Link>

          {children.length > 0 ? <ListTree items={children} /> : null}
        </li>
      </ul>
    ))}
  </>
);

export default ListTree;
