import React from 'react';
import useSiteTree from 'hooks/useSiteTree';

import { Layout } from 'layout';
import ListTree from 'components/ListTree';

const Sitemap: React.FC = () => {
  const tree = useSiteTree();

  return (
    <Layout>
      <div className="sitemap__container container">
        <h1 className="sitemap__title">Sitemap</h1>
        <div className="sitemap__content">
          <ListTree items={tree} />
        </div>
      </div>
    </Layout>
  );
};

export default Sitemap;
